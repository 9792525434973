import { useAuthState } from '~/stores/auth'

export default defineNuxtRouteMiddleware((to, from) => {
  return console.log('authadminonly is disabled')

  console.log('authadminonly middleware running')

  // Get auth state
  const auth = useAuthState()

  const nuxtApp = useNuxtApp()
  const { ssrContext } = nuxtApp

  if (ssrContext) {
    return console.log('Auth state middleware running in SSR. Skipping..')
  } else { console.log('Auth state middleware running client-side..') }

  if (!auth.isAuthed) {
    console.log('!auth.isAuthed - Redirecting to /login')
    return navigateTo('/login')
  }

  if (auth.userIsTenantAdmin) {
    // console.log(`Auth check: User is admin: Allow`)
    return // Allow the navigation
  }

  // Otherwise redirect to login
  return navigateTo('/login')
})
